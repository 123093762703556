import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

/******************************
 * RequestAuth
 * exported to App.js
 *****************************/
export default function RequestAuth({ children }) {
  const user = useSelector((state) => state.login.userState);
  let location = useLocation();

  if (!user) {
    // provides a redirection to the login page, providing sucurity and
    // good UX
    return <Navigate to={"/"} state={{ from: location }} replace />;
  }
  return children;
}
